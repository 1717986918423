<template>
  <ModalWrapper @close="closeAction">
    <template #head>
      <div class="flex ai-c ggap-10">
        <h1 class="title">{{ formData.id ? 'Редактировать' : 'Забронировать' }}</h1>
      </div>
    </template>

    <template #default v-if="isDelete">
      <div v-if="isDelete" class="item__delete flex fd-c ai-c jc-c ggap-10">
        <div v-if="isLoad" class="flex fd-c ai-c ggap-5">
          <BaseLoad class="rel sm" />
          <h3 class="title">Идет удаление записи!</h3>
        </div>
        <div class="p-20" v-else>
          <div
            class="flex ai-c jc-sb mb-20"
            @click.prevent.stop="formData.delete_all = !formData.delete_all"
            v-if="formData.repeat_type !== 'none' && formData?.id"
          >
            <h3 class="m-0">Удалить все будущие повторения</h3>
            <button type="button" class="btn transparent-grey sm pl-10">
              <FormFakeSwitch :isTrue="formData.delete_all" />
            </button>
          </div>
          <h3 class="title">Вы действительно хотите удалить бронирование?</h3>
        </div>
      </div>
    </template>

    <template #default v-else>
      <div>
        <div class="form-add pos-r z0 p-20 grid ggap-30" id="form-add">
          <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white" />
          <div v-if="!Object.keys(freeRooms).length" class="grid ggap-20">
            <div class="grid gtc-1 ggap-20">
              <FormInput type="text" required label="Название встречи" v-model="formData.name" />
            </div>
            <div class="grid gtc-1 ggap-20">
              <FormDate
                type="date"
                required
                format="YYYY-MM-DD"
                label="Выберите день"
                placeholder="-"
                v-model="formData.date"
              />
            </div>

            <div class="grid gtc-2 ggap-10">
              <FormSelect label="Начало" required class="sm white" :options="startOptions" v-model="formData.start" />
              <FormSelect label="Конец" required class="sm white" :options="endOptions" v-model="formData.due" />
            </div>

            <div class="grid gtc-1 ggap-10">
              <FormSelect
                label="Периодичность"
                required
                class="sm white"
                :options="repeatOptions"
                :disabled="formData.id"
                v-model="formData.repeat_type"
                :withoutDefault="true"
              />
            </div>

            <div class="grid gtc-1 ggap-10" v-if="formData.repeat_type != 'none'">
              <FormDate
                type="date"
                required
                format="YYYY-MM-DD"
                label="Повтор до:"
                placeholder="-"
                :disabled="formData.id"
                v-model="formData.end_of_booking"
                :startDate="startDate"
                :endDate="endDate"
              />
            </div>

            <div
              class="flex ai-c jc-sb"
              @click.prevent.stop="formData.update_all = !formData.update_all"
              v-if="formData.repeat_type !== 'none' && formData?.id"
            >
              <h3 class="m-0">Обновить все повторения</h3>
              <button type="button" class="btn transparent-grey sm pl-10">
                <FormFakeSwitch :isTrue="formData.update_all" />
              </button>
            </div>

            <div class="grid gtc-1">
              <FormAutocompleteSearch
                class="f-1"
                label="Участники"
                placeholder="Выберите участников"
                :options="optionsUsers"
                :isLoad="isUsersLoad"
                :isMultiple="true"
                @search="searchUsers($event)"
                v-model="formData.user"
              >
                <template #tag="{ item }">
                  <ItemUser :item="item" @remove="removeUserItem" />
                </template>
              </FormAutocompleteSearch>
            </div>
          </div>

          <div class="grid gtc-1 ggap-10" v-if="Object.keys(freeRooms).length" :class="[isMobile ? 'w-100' : '']">
            <span class="flex jc-sb" :class="[isMobile ? 'fd-c ai-fs' : 'ai-c']">
              <button class="btn primary" @click="freeRooms = {}">Назад</button>
              <div class="flex ai-c jc-sb" @click.prevent.stop="formData.skip_all = !formData.skip_all">
                <h3 class="m-0">Пропустить занятые дни</h3>
                <button type="button" class="btn transparent-grey sm pl-10">
                  <FormFakeSwitch :isTrue="formData.skip_all" />
                </button>
              </div>
            </span>
            <h3 class="m-0">
              Некоторые переговорные заняты на этот период времени, вы можете заменить переговорную. Так же Вы можете
              пропустить бронирование переговорных на эти дни!
            </h3>
            <div class="free-meeting-rooms cursor-d" v-for="(freeTime, key) in freeRooms" :key="key">
              <div class="mt-10 mb-10">
                <div class="fs-22 mr-10">
                  {{ key }}
                </div>
                {{ formData.start }} - {{ formData.due }}
              </div>
              <div class="flex ggap-10 horizontal-scroll pb-10 scroll-mr">
                <div
                  class="flex ai-c jc-sb rounded w-160 h-90 pos-r user-select-none"
                  v-for="room in freeTime"
                  v-show="freeTime.length"
                  :class="{ 'choosed-item': choosed(room, key) }"
                  :key="room.id"
                  @click="setChoosedRoom(room, key)"
                >
                  <div class="pointer hr overflow-h pos-r w-100 h-100">
                    <img :src="room.image" class="w-100 h-100 fit" />
                    <div class="flex ai-c jc-c w-100 var-grey pos-a title-name">
                      <div class="cut ta-center">{{ room.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #foot>
      <div class="flex ai-c ggap-20" :class="{ 'form-add__disabled': isLoad }">
        <div class="flex ai-c ggap-10 ml-auto" v-if="!isDelete">
          <button class="btn transparent-grey" @click.prevent="closeAction">Отмена</button>
          <button v-if="formData.id" class="btn secondary" @click.stop="isDelete = !isDelete">Удалить</button>
          <button class="btn primary" @click.prevent="submitAction">
            {{ formData.id ? 'Сохранить' : 'Забронировать' }}
          </button>
        </div>
        <div class="flex ai-c jc-sb w-100" v-else>
          <div class="flex ai-c ggap-10 ml-auto">
            <button class="btn cancel sm" @click.stop="isDelete = false">Отмена</button>
            <button class="btn primary sm" @click.stop="deleteBooking()">Удалить</button>
          </div>
        </div>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { useStore } from 'vuex'
import { defineEmits, defineProps, ref, toRefs, computed, watch } from 'vue'
import { isInvalidForm, $busEmit } from '@/plugins'
import {
  BaseLoad,
  ModalWrapper,
  FormDate,
  FormSelect,
  FormAutocompleteSearch,
  ItemUser,
  FormInput,
  FormFakeSwitch
} from '@/components'
import { meetingRooms } from '@/api'

// Emits
const emits = defineEmits(['close'])

// Props
const props = defineProps({
  currentId: {
    type: [Number, String],
    default: 0
  }
})

// Data
const startOptions = computed(() => {
  const startHour = moment().format('HH')
    ? 8
    : moment(formData.value?.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && startHour < 20
    ? startHour
    : 8
  const array = []
  for (let i = Number(8); i < 20; i++) {
    array.push(
      {
        text: i < 10 ? `0${i}:00` : i + ':00',
        value: i < 10 ? `0${i}:00` : i + ':00',
        number: i
      },
      {
        text: i < 10 ? `0${i}:30` : i + ':30',
        value: i < 10 ? `0${i}:30` : i + ':30',
        number: i + 0.5
      }
    )
  }
  return array
})

const endOptions = computed(() => {
  const index = startOptions.value?.find((el) => el.text === formData.value?.start)
  const result = startOptions.value.filter((el) => el?.number > index?.number)
  return [...result, { text: '20:00', value: '20:00', number: 20 }]
})

const repeatOptions = [
  { text: 'Не повторять', value: 'none' },
  { text: 'Каждый день', value: 'per_day' },
  { text: 'Каждую неделю', value: 'per_week' },
  { text: 'Каждый месяц', value: 'per_month' }
]

momentDurationFormatSetup(moment)
const { currentId } = toRefs(props)
const optionsUsers = computed(() => store.getters.users || [])
const isMobile = computed(() => store.getters.isMobile)
const currentDate = computed(() => store.getters['meetingRooms/date'])
const store = useStore()
const isDelete = ref(false)
const isLoad = ref(false)
const isUsersLoad = ref(false)
const reserveTomorrow =
  moment(currentDate.value).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && moment().format('HH') > 20
const defaultFormData = {
  name: '',
  date: reserveTomorrow ? moment().add(1, 'day').format('YYYY-MM-DD') : currentDate.value,
  start:
    currentId.value?.hour >= moment().format('HH')
      ? getHour(Number(currentId.value?.hour))
      : getHour(Number(moment().format('HH'))),
  due: currentId.value?.hour > 19 ? getHour(Number(currentId.value?.hour), 1) : '',
  repeat_type: 'none',
  user: [],
  end_of_booking: '',
  skip_all: false,
  delete_all: false,
  update_all: false
}

let formData = ref({ ...defaultFormData })
const freeRooms = ref({})
const choosedRooms = ref([])
const startDate = computed(() => {
  return moment(formData.value?.date).add(1, 'day').format('YYYY-MM-DD')
})
const endDate = computed(() => {
  switch (formData.value?.repeat_type) {
    case 'per_day':
      return moment(formData.value?.date).add(1, 'month').format('YYYY-MM-DD')
    case 'per_week':
      return moment(formData.value?.date).add(3, 'month').format('YYYY-MM-DD')
    case 'per_month':
      return moment(formData.value?.date).add(6, 'month').format('YYYY-MM-DD')
    default:
      return moment(formData.value?.date).add(1, 'month').format('YYYY-MM-DD')
  }
})

// Created
currentId.value?.booking?.id && getItem(currentId.value?.booking)

watch(formData.value, () => {
  if (formData.value.start && formData.value.due) {
    const start = startOptions.value.find((el) => el.value === formData.value.start)
    const end = endOptions.value.find((el) => el.value === formData.value.due)
    if (start?.number > end?.number || !end?.number || start?.number === end?.number) {
      formData.value.due = ''
    }
  }

  if (formData.value?.repeat_type === 'none') {
    freeRooms.value = []
  }

  if (new Date(formData.value?.date).getTime() > new Date(formData.value?.end_of_booking).getTime()) {
    formData.value.end_of_booking = ''
  }

  if (formData.value?.skip_all) {
    choosedRooms.value = []
  }
})

const choosed = (room, key) => choosedRooms.value?.find((el) => el.time === key)?.negotiation_id === room.id

function getItem(data) {
  formData.value = {
    name: data?.name,
    date: moment(data?.date_start).format('YYYY-MM-DD'),
    start: moment(data?.date_start).format('HH:mm'),
    due: moment(data?.date_end).format('HH:mm'),
    repeat_type: data?.repeat_type,
    end_of_booking: moment(data?.end_of_booking).format('YYYY-MM-DD'),
    user: data?.user || [],
    id: data?.id,
    owner: data?.owner
  }
}

function getHour(time, add = 0) {
  let t = time + add
  if (time + add > 19) {
    t = 19
  } else if (time + add < 7) {
    t = 8
  }
  return `${t < 10 ? '0' + t + ':00' : t + ':00'}`
}

function closeAction() {
  emits('close')
}

async function searchUsers(searchText) {
  isUsersLoad.value = true
  await store.dispatch('GET_USERS', searchText)
  isUsersLoad.value = false
}

function removeUserItem(id) {
  const index = formData.value?.user?.findIndex((el) => el.id === id)
  formData.value?.user?.splice(index, 1)
}

async function submitAction() {
  if (isInvalidForm('form-add')) return
  isLoad.value = true
  if (formData.value?.repeat_type !== 'none') {
    if (!Object.keys(freeRooms.value).length && formData.value?.end_of_booking) {
      const data = await getFreeRooms()
      if (Object.keys(data).length) {
        freeRooms.value = data
        isLoad.value = false
      } else {
        reserveMeetingRoom()
      }
    } else {
      reserveMeetingRoom()
    }
  } else {
    reserveMeetingRoom()
  }
}

async function reserveMeetingRoom() {
  const payload = new FormData()
  payload.append('name', formData.value?.name)
  payload.append('date_start', `${formData?.value?.date} ${formData?.value?.start}`)
  payload.append('date_end', `${formData?.value?.date} ${formData?.value?.due}`)
  payload.append('negotiation_id', currentId.value?.meetingRoomId)

  for (let i = 0; i < formData.value?.user.length; i++) {
    payload.append('users[]', formData.value?.user[i]?.id)
  }

  formData.value?.repeat_type && payload.append('repeat_type', formData.value?.repeat_type)
  if (formData.value?.id) {
    payload.append('id', formData.value?.id)
    payload.append('_method', 'patch')
  }

  if (formData.value?.repeat_type != 'none' && formData.value?.end_of_booking) {
    payload.append('end_of_booking', formData.value?.end_of_booking + ' 00:00')
  }

  if (formData.value?.repeat_type !== 'none' && freeRooms.value.length) {
    payload.append('skip_all', formData.value?.skip_all)
  }

  if (formData.value?.repeat_type !== 'none' && formData.value.id && formData.value?.update_all) {
    payload.append('update_all', Number(formData.value?.update_all))
  }

  if (choosedRooms.value.length && !formData.value?.skip_all) {
    for (let i = 0; i < choosedRooms.value.length; i++) {
      payload.append(`replace_date[${i}]`, choosedRooms.value[i]?.time)
      payload.append(`replace_negotiation_id[${i}]`, choosedRooms.value[i]?.negotiation_id)
    }
  }
  submitActionHandler(payload)
}

async function submitActionHandler(payload) {
  try {
    isLoad.value = true
    const id = formData.value?.id
    const url = `meetingRooms/${id ? 'UPDATE_BOOKING' : 'ADD_BOOKING'}`
    const { data } = await store.dispatch(url, { id: formData.value?.id, payload })
    if (id) {
      store.commit('meetingRooms/CHANGE_BOOKING_ITEM', { ...formData.value })
    } else {
      data?.data?.id &&
        store.commit('meetingRooms/PUSH_BOOKING_ITEM', {
          data: data?.data,
          negotiation_id: currentId.value?.meetingRoomId
        })
    }
    const message = id ? 'Бронирование успешно изменено!' : 'Переговорная успешно забронирована!'
    if (currentDate.value !== formData?.value?.date) await store.dispatch('meetingRooms/GET_ROWS')
    await store.dispatch('meetingRooms/GET_ROWS')
    await store.dispatch('helpers/GET_USER_EVENTS')
    $busEmit('updateBooking')
    $busEmit('setToast', { type: 'green', icon: 'check-circle', message: message })
    closeAction()
  } catch (error) {
    let errtext = 'Выбранные дата и время бронирования уже заняты, выберите другое время!'
    if (!error?.response?.data?.message.includes('busy') && error?.response?.status !== 400) {
      errtext = 'Произошла ошибка при бронировании, обратитесь к Администратору.'
    }
    $busEmit('setToast', { type: 'red', icon: 'alert-triangle', message: errtext })
    isLoad.value = false
  } finally {
    isLoad.value = false
  }
}

async function deleteBooking() {
  try {
    await store.dispatch('meetingRooms/DELETE_BOOKING', { id: formData.value?.id, all: formData.value?.delete_all })
    store.commit('meetingRooms/DELETE_BOOKING_ITEM', {
      ...formData.value,
      negotiation_id: currentId.value?.meetingRoomId
    })
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Бронирование успешно отменено'
    })
    $busEmit('updateBooking')
    store.dispatch('helpers/GET_USER_EVENTS')
  } catch (error) {
    console.log(error)
    $busEmit('setToast', {
      type: 'red',
      icon: 'alert-triangle',
      message: 'При удалении бронирования произошла ошибка'
    })
  } finally {
    isLoad.value = false
    closeAction()
  }
}

async function getFreeRooms() {
  const obj = {
    ...formData.value,
    negotiation_id: currentId.value?.meetingRoomId,
    date_start: `${formData?.value?.date} ${formData?.value?.start}`,
    date_end: `${formData?.value?.date} ${formData?.value?.due}`,
    end_of_booking: `${formData?.value?.end_of_booking} 00:00`
  }
  if (formData.value?.id) obj.booking_id = formData.value?.id

  return new Promise((resolve, reject) => {
    meetingRooms
      .getFreeMeetingRooms(obj)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
        const message = 'Произошла ошибка при получении списка свободных переговорок, попробуйте позже!'
        $busEmit('setToast', { type: 'red', icon: 'alert-triangle', message })
      })
  })
}

function setChoosedRoom(room, time) {
  const index = choosedRooms?.value?.findIndex((el) => el.time === time)
  formData.value.skip_all = false
  if (index === -1) {
    choosedRooms.value.push({
      time,
      negotiation_id: room?.id
    })
  } else {
    if (choosedRooms.value[index].negotiation_id === room?.id) {
      choosedRooms.value[index].negotiation_id = ''
    } else {
      choosedRooms.value[index].negotiation_id = room?.id
    }
  }
}
</script>

<style scoped>
.title-name {
  bottom: 0;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.2s all linear;
  font-size: 16px;
  color: white;
  padding: 0 10px;
  filter: blur(0);
}
.form-add {
  width: 50vw;
}
.choosed-item .title-name {
  height: 100%;
  font-size: 20px;
}
.choosed-item img {
  filter: blur(5px);
}
.scroll-mr {
  width: calc(50vw - 40px);
}
@media screen and (max-width: 800px) {
  .form-add {
    width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
  }
  .scroll-mr {
    width: calc(100vw - 80px);
  }
}
</style>
